import admin from '@/views/admin'
import purchase from '@/views/purchase'
import clients from '@/views/clients'
import team from '@/views/team'
import operations from '@/views/operations'
import changePassword from '@/views/changePassword'

// Tech
import error from '@/views/error'
import videoInstruction from '@/views/videoInstruction'

// Welcome
import welcome from '@/views/welcome'

// Messages
import messages from '@/views/messages'
import messagesService from '@/views/messagesService'
import messagesNews from '@/views/messagesNews'
import messagesAutomation from '@/views/messagesAutomation'
import messagesUnanswered from '@/views/messagesUnanswered'
import messagesCreateAutomation from '../views/messagesCreateAutomation'
import messagesManageNews from '../views/messagesManageNews'
import messagesCreateGroup from '@/views/messagesCreateGroup'

// Analytics
import analytics from '@/views/analytics'
import analyticsClients from '@/views/analyticsClients'
import analyticsOperations from '@/views/analyticsOperations'
import analyticsBehavior from '@/views/analyticsBehavior'
import analyticsActivity from '@/views/analyticsActivity'

// Customer
import customer from '@/views/customer'
import customerInformation from '@/views/customerInformation'
import customerBonuses from '@/views/customerBonuses'
import customerMessages from '@/views/customerMessages'
import customerPurchases from '@/views/customerPurchases'
import customerRecommendations from '@/views/customerRecommendations'

// Account
import account from '@/views/account'
import accountNew from '@/views/accountNew'
import accountLogin from '@/views/accountLogin'
import accountRestore from '@/views/accountRestore'
import accountPasswordReset from '@/views/accountPasswordReset'

// Import
import importView from '@/views/importView'
import importBase from '@/views/importBase'
import smsFirst from '@/views/smsFirst'
import smsIntegration from '@/views/smsIntegration'

// Settings

import settings from '@/views/settings'
import settingsProfile from '@/views/settingsProfile'
import settingsBonuses from '@/views/settingsBonuses'
import settingsReferrals from '@/views/settingsReferrals'
import settingsIntegrations from '@/views/settingsIntegrations'
import settingsIntegrationsWeb from '@/views/settingsIntegrationsWeb'
import settingsIntegrationsViber from '@/views/settingsIntegrationsViber'
import settingsIntegrationsTelegram from '@/views/settingsIntegrationsTelegram'
import settingsIntegrationsVK from '@/views/settingsIntegrationsVK'

export default [
  {
    path: '/admin',
    redirect: '/admin/clients',
    component: admin,
    children: [
      {
        path: 'clients',
        component: clients
      },
      {
        path: 'settings',
        redirect: '/admin/settings/profile',
        component: settings,
        children: [
          {
            path: 'profile',
            component: settingsProfile
          },
          {
            path: 'bonuses',
            component: settingsBonuses
          },
          {
            path: 'referrals',
            component: settingsReferrals
          },
          {
            path: 'service',
            component: messagesService
          },
          {
            path: 'integrations',
            component: settingsIntegrations,
            redirect: '/admin/settings/integrations/web',
            children: [
              {
                path: 'web',
                component: settingsIntegrationsWeb
              },
              {
                path: 'telegram',
                component: settingsIntegrationsTelegram
              },
              {
                path: 'viber',
                component: settingsIntegrationsViber
              },
              {
                path: 'vk',
                component: settingsIntegrationsVK
              }
            ]
          }
        ]
      },
      {
        path: 'change-password',
        component: changePassword
      },
      {
        path: 'purchase',
        component: purchase
      },
      {
        path: 'team',
        component: team
      },
      {
        path: 'operations',
        component: operations
      },
      {
        path: 'import',
        redirect: '/admin/import/base',
        component: importView,
        children: [
          {
            path: 'base',
            component: importBase
          },
          {
            path: 'sms-first',
            component: smsFirst
          },
          {
            path: 'sms-integration',
            component: smsIntegration
          }
        ]
      },
      {
        path: 'analytics',
        component: analytics,
        redirect: '/admin/analytics/clients',
        children: [
          {
            path: 'clients',
            component: analyticsClients
          },
          {
            path: 'operations',
            component: analyticsOperations
          },
          {
            path: 'behavior',
            component: analyticsBehavior
          },
          {
            path: 'activity',
            component: analyticsActivity
          }
        ]
      },
      {
        path: 'messages',
        redirect: '/admin/messages/automation',
        component: messages,
        children: [
          {
            path: 'automation',
            component: messagesAutomation
          },
          {
            path: 'automation/create',
            component: messagesCreateAutomation
          },
          {
            path: 'news',
            component: messagesNews
          },
          {
            path: 'news/:id',
            component: messagesManageNews,
            name: 'editNews'
          },
          {
            path: 'news/create',
            component: messagesManageNews,
            name: 'createNews'
          },
          {
            path: 'unanswered',
            component: messagesUnanswered
          },
          {
            path: 'creategroup',
            component: messagesCreateGroup
          }
        ]
      },
      {
        path: 'customer/:id',
        name: 'customer',
        component: customer,
        redirect: to => ({
          path: `/admin/customer/${to.params.id}/information/`
        }),
        children: [
          {
            path: 'information',
            component: customerInformation
          },
          {
            path: 'bonuses',
            component: customerBonuses
          },
          {
            path: 'messages',
            component: customerMessages
          },
          {
            path: 'purchases',
            component: customerPurchases
          },
          {
            path: 'recommendations',
            component: customerRecommendations
          }
        ]
      }
    ]
  },
  {
    path: '/',
    redirect: '/create',
    component: account,
    children: [
      {
        path: '/create',
        component: accountNew
      },
      {
        path: '/login',
        component: accountLogin,
        name: 'login'
      },
      {
        path: '/restore',
        component: accountRestore
      },
      {
        path: '/reset-password',
        component: accountPasswordReset
      }
    ]
  },
  {
    path: '/instruction',
    name: 'instruction',
    component: videoInstruction
  },
  {
    path: '/welcome/:id',
    name: 'welcome',
    component: welcome
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error',
    component: error
  }
]
