export const adaptNews = (data, mode)=>{
  const form = {}
  form.bonuses = data.bonuses
  form.burn_days = data.burn_days
  form.nabb_text = data.nabb_text
  form.nbbb_text = data.nbbb_text
  form.nbbb_days = data.nbbb_days
  form.text = data.text
  form.title = data.title
  form.image = data.image

  if(mode){
    form.action_type = data.action_type === 'Начислить бонусы и отправить сообщение' ? 0 : 1
    form.send_type = data.send_type === 'Сразу' ? 0 : 1
    form.bonuses_burn = data.bonuses_burn === 'Нет' ? 0 : 1
    form.notify_before_bonuses_burn = data.notify_before_bonuses_burn === 'Нет' ? 0 : 1
    form.notify_after_bonuses_burn = data.notify_after_bonuses_burn === 'Нет' ? 0 : 1


    if(!form.send_type) {
      delete form.send_date
      delete form.send_time
    } else {
      form.send_date = data.send_date
      form.send_time = data.send_time

    }
  } else {
    form.action_type = data.action_type === 0 ? 'Начислить бонусы и отправить сообщение' : 'Отправить сообщение'
    if(data.send_type){
      const [day, month, year] = data.send_date.split('.');
      form.send_date = `${year}-${month}-${day}`
      form.send_time = data.send_time
    }
    form.send_type = data.send_type === 0 ? 'Сразу' : 'Запланировать'
    form.bonuses_burn = data.bonuses_burn === 0 ? 'Нет' : 'Да'
    form.notify_before_bonuses_burn = data.notify_before_bonuses_burn === 0 ? 'Нет' : 'Да'
    form.notify_after_bonuses_burn = data.notify_after_bonuses_burn === 0 ? 'Нет' : 'Да'

  }

  return form
}
