<template>
  <div class="msg-header">
    <div class="msg-header-button">
      <xButton @click="$router.push('/admin/messages/news/create')">
        <icon class="xbutton-icon" name="create"></icon>
        Создать
      </xButton>
    </div>
    <div class="msg-header-notify">
      <adminNotify color="orange" >
        <span class="note-wrapper">
          Не рекомендуем создавать избыточное количество сообщений без бонусов – нет клиентов, которые любят спам
          <note>
            Информируйте клиентов о ВАЖНЫХ ДЛЯ НИХ событиях и новостях. У вас поступление новой коллекции? Вы запустили выгодную акцию? Вы открыли новую точку продаж? Проинформируйте об этом клиентов.
          </note>
        </span>
      </adminNotify>
    </div>
  </div>
  <div class="table-container">
    <xTable v-bind="getTableData()">
      <template v-slot="{ records }">
        <td>
          <div class="table-action">
            <button @click="actionNews(records.id, 'edit')">
              <icon name="edit" class="table-icon"/>
            </button>
            <button @click="newsActionId =records.id; popUpCopy = true">
              <icon name="copy" class="table-icon"/>
            </button>
            <button @click="newsActionId =records.id; popUpDelete = true">
              <icon name="delete" class="table-icon"/>
            </button>
          </div>
        </td>
        <td>
          <div class="msg-news-send">
            <div v-if="msgSent">
              <div class="msg-news-date">Отправлено 27.04.2021 в 10:00</div>
            </div>
            <div v-else class="msg-news-send-button">
              <xButton @click="send">Отправить</xButton>
            </div>
          </div>
        </td>
      </template>
    </xTable>
  </div>
  <pop-up :open="popUpCopy" @close="popUpCopy = !popUpCopy" sizer="pop-small">
    <div class="x-pop-content__center">
      <p class="x-pop-desc-black">
        После создания копии цепочки авто-сообщений обязательно внесите изменения. Если вы этого не сделаете, клиенту будет отправляться 2 одинаковых копии сообщений
      </p>
      <div class="x-pop-footer__center">
        <xButton @click="actionNews(newsActionId, 'copy');popUpCopy = false">Продолжить</xButton>
        <button @click="popUpCopy = false" class="link x-pop-back">Отмена</button>
      </div>
    </div>
  </pop-up>
  <pop-up :open="popUpDelete" @close="popUpDelete = !popUpDelete" sizer="pop-small">
    <h2 class="x-pop-title">Вы удаляете сообщение</h2>
    <p class="x-pop-desc-black">
      Все уже запланированные сообщения (например, сообщение о скором сгорании бонусов) будут отправлены. Все уже запланированные действия с бонусами (например, сгорание начисленного ранее и не использованного бонуса) будут выполнены. Пожалуйста, подтвердите удаление
    </p>
    <div class="x-pop-footer">
      <xButton @click="actionNews(newsActionId, 'delete');popUpDelete = false">Удалить</xButton>
      <button @click="popUpDelete = false" class="link x-pop-back">Отмена</button>
    </div>
  </pop-up>
</template>

<script>
import xTable from '@/components/xTable'
import xButton from '@/components/xButton'
import adminNotify from '@/components/adminNotify'
import note from '@/components/note'
import popUp from '@/components/popUp';
import {fetchData} from '@/rest/api';

export default {
  name: 'messagesNews',
  components: {
    popUp,
    xTable,
    xButton,
    adminNotify,
    note
  },
  data() {
    return {
      msgSent: false,
      popUpCopy: false,
      popUpDelete: false,
      newsActionId: null,
      news: []
    }
  },
  methods: {
    async getNews(){
      const {data} = await fetchData('news')
      this.news = data.newsMessages.data.map((item)=>{
        return {
          title: item.title,
          text: item.text,
          id: item.id
        }
      })
    },
    async actionNews(id, action) {
      switch (action) {
        case 'delete':{
          const resp = await fetchData(`news/${id}`, {method: 'DELETE'})
          if(resp.success){
            await this.getNews()
          }
          break;
        }
        case 'copy':{
          const resp = await fetchData(`news/${id}/copy`, {method: 'GET'})
          if(resp.success){
            await this.getNews()
          }
          break;
        }
        case 'edit':{
          this.$router.push(`/admin/messages/news/${id}`)
          break;
        }
      }
    },
    getTableData() {
      return {
        theads: 2,
        messagesEvents: true,
        showToggler: false,
        showCheckboxes: false,
        currentPageX: 1,
        selectedRowsX: [],
        totalPagesX: 1,
        data: this.news,
        wordChecker: true,
        align: 'left',
        optionsX: [
          {name: 'Наименование сообщения', value: 'message', id: 0}
        ],
        allNamesX: [],
        newNamesX: [],
        defaultNamesX: ['Наименование сообщения'],
        namesForHeaderX: []
      }
    },
    send() {
      this.msgSent = !this.msgSent
    }
  },
  async mounted() {
    await this.getNews()
  }
}
</script>

<style lang="scss" src="./messagesNews.scss" scoped/>
