const url = 'https://bonusplan.online/api/v1/'
export const fetchData = async (endpoint, options = {}) => {

  try {
    const response = await fetch(url + endpoint, {
      ...options,
      headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token') || ''}`}
    });

    if (!response.ok) {
      throw new Error(JSON.stringify({statusCode: response.status, statusMessage: response.statusText}));
    }

    return response.json();
  } catch (e) {
    console.log(e)
    return undefined
  }
};
